.iEQPzt {
  display: flex;
  width: 20.3rem;
  border-radius: 1rem;
  color: rgb(255, 255, 255);
  background-image: linear-gradient(
    71.04deg,
    rgb(39, 57, 101) -6.92%,
    rgb(80, 210, 149) 109.43%
  );
  padding: 1rem;
  gap: 1.5rem;
  cursor: pointer;
}

.imBptA {
  width: 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.cwqblp {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 2.5rem;
  object-fit: cover;
}

.WAbSq {
  color: rgba(255, 255, 255, 0.5);
  font-size: 8px;
  text-align: center;
}

.jbPWCT {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 180px;
}

.gJgZBc {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 1.25rem;
  padding: 0.25rem 0.5rem;
  width: fit-content;
  font-size: 12px;
  text-align: center;
}

.vOLKx {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  max-height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jJltku {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 0.75rem;
}

.iWrqRd {
  border: 1px dashed rgb(255, 255, 255);
  border-radius: 0.25rem;
  max-width: 140px;
  background: rgba(255, 255, 255, 0.2);
  padding: 0.25rem 1.5rem;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.5s;
}

.iWrqRd:hover {
  background-color: #447596;
}


