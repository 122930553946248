@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .hoverEffect {
    @apply duration-300 cursor-pointer;
  }
  .headerSedenavLi {
    @apply hover:underline underline-offset-2 cursor-pointer text-gray-300 hover:text-white;
  }
}

body {
  background-color: #f5f5f3;
}

*::-webkit-scrollbar {
  display: none;
}

#pagination-bar li {
  border-radius: 50px;
}
/* ============= NavLink active Start here ============== */
nav .active li {
  color: #000;
  font-weight: 600;
  border-bottom: 1.8px solid #000;
  padding: 0 8px 0 0;
}
nav li:hover {
  color: #000;
}
.small-sidebar {
  display: none;
}

#display-none {
  display: none;
}

.blend-images {
  width: 70px;
  padding: 5px;
  aspect-ratio: 3/2;
  object-fit: contain;
}

.swap-btn {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #fff;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-decoration: none;
  letter-spacing: 1px;
  border-top: 0.5px solid rgba(255, 255, 255, 0.35);
  border-left: 0.5px solid rgba(255, 255, 255, 0.35);
  padding-left: 40px;
  transition: 0.5s;
  overflow: hidden;
  font-weight: 600;
}

.swap-btn:hover {
  padding-left: 0px;
  padding-right: 40px;
  color: rgba(0, 0, 0, 0);
}

.swap-btn span {
  position: absolute;
  left: 5px;
  width: 50px;
  height: 50px;
  background: rgb(4 123 181);
  border-radius: 50px;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.5rem;
}

.swap-btn:hover span {
  left: calc(100% - 55px);
}

.swap-btn::after {
  content: " ";
  position: absolute;
  width: 0px;
  height: 100%;
  z-index: 999;
  transform: translateX(-170px) skewX(30deg);
  transition: 0.75s ease-in-out;
}

.swap-btn:hover:after {
  transform: translateX(170px) skewX(30deg);
}

.cetagories-box--items {
  width: 200px;
  height: 200px;
  padding: 12px;
  border-radius: 5px;
  background-color: #e8fcf1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-inner--container {
  border-radius: 5px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #00b98e;
}

.item-inner--container:hover {
  background-color: #00b98e;
  transition: 0.5s;
}

.round {
  margin-bottom: 1rem;
  padding: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff !important;
  border-radius: 50px;
  border: 1px dashed #00b98e !important;
}

.item-inner--container h6 {
  font-size: 1rem;
  color: #0e2e50;
}

@media screen and (max-width: 665px) {
  *::-webkit-scrollbar {
    display: none;
  }
  .webkit-show::-webkit-scrollbar {
    padding: 2px;
  }

  .product-img {
    height: 300px;
  }
  .small-sidebar {
    display: flex;
  }
  .flex-item-col {
    flex-direction: column;
  }
  .grid-2--cols {
    grid-template-columns: repeat(1, 1fr);
  }
  #flex-center {
    width: 100%;
  }
  #sidebar {
    display: none;
  }

  #display-none {
    display: flex;
  }

  #display-show {
    display: none;
  }
  nav .active li {
    color: white;
    font-weight: 600;
  }
  .location-bar {
    position: absolute;
    right: 78px;
  }
  .location-bar {
    display: none;
  }
  .full-width {
    width: 100%;
  }
  .grd-1-col {
    grid-template-columns: repeat(1, 1fr);
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
/* ============= NavLink active End here ================ */
